window.HB = window.HB || {};

function ready(fn) {
  if (
    document.attachEvent
      ? document.readyState === "complete"
      : document.readyState !== "loading"
  ) {
    fn();
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
}

HB.core = (function () {
  "use strict";

  const initSiteNav = () => {
    // Add event listeners to toggle mobile menu open and closed
    const nav = document.querySelector("#nav");
    const navToggle = document.querySelector(".header__toggle");

    function toggleNav() {
      navToggle.setAttribute(
        "aria-expanded",
        navToggle.getAttribute("aria-expanded") != "true"
      );
      document.querySelector("body").classList.toggle("nav-open");
    }

    navToggle.addEventListener("click", toggleNav);

    nav.addEventListener("click", (e) => {
      if (e.target.nodeName === "A") {
        toggleNav();
      }
    });
  };

  const initNavObserver = () => {
    function callback(entries) {
      for (const entry of entries) {
        // console.log(
        //   entry.target,
        //   entry.isIntersecting,
        //   entry.intersectionRatio
        // );
        const hash = "#" + entry.target.id;
        const navEl = document.querySelector(`a[href="${hash}"]`);
        if (entry.isIntersecting && entry.intersectionRatio > 0.5) {
          navEl.classList.add("text-aqua");
        } else {
          navEl.classList.remove("text-aqua");
        }
      }
    }
    const iObserver = new IntersectionObserver(callback, {
      root: null,
      threshold: 0.5,
    });

    iObserver.observe(document.querySelector("#welcome"));
    iObserver.observe(document.querySelector("#our-history"));
    iObserver.observe(document.querySelector("#criteria"));
  };

  const init = function () {
    document.querySelector("html").classList.remove("no-js");
    document.querySelector("html").classList.add("js");

    initSiteNav();
    initNavObserver();
  };

  return {
    init: init,
  };
})();

ready(function () {
  HB.core.init();
});
